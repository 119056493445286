<script lang="ts">
	import Icon from "./Icon.svelte";
	import arrowDown from "../../../core/assets/icons/filled-arrow-down.svg?raw";
	import Link from "./Link.svelte";
	import { referencesPathMap } from "../../../core/schema/paths/referencesPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import bannerVideoMp4 from "../assets/videos/banner-video-optimized.mp4";
	import bannerVideoPoster from "../assets/videos/banner-video-poster.jpg?webp&w=1920&imagetools";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import { servicesAnchorPathMap } from "../../../core/schema/paths/servicesAnchorPathMap";

	const { TENANT_LOCALE } = getGlobalSettings();
</script>

<div class="absolute top-0 h-[calc(80vh+5rem)] w-full overflow-hidden">
	<StaticVideo
		poster={bannerVideoPoster}
		video={bannerVideoMp4}
		class="absolute -top-[10%] h-[150%] w-[150%] object-cover xl:-top-[10%] xl:h-[120%] xl:w-[120%]"
		videoClass="absolute inset-0 object-cover !h-full !w-full"
		loading="eager"
	/>
</div>
<div class="absolute inset-0 h-[calc(80vh+5rem)] bg-black opacity-20"></div>

<div class="relative mt-20 flex h-[80vh] w-full flex-col items-center justify-center px-4">
	<div class="absolute left-0 top-[15%] flex md:top-[13%]">
		<div class="bg-primary h-3 w-[50vw] xl:w-[34vw] min-[2160px]:w-[50vw]"></div>
		<div class="bg-primary h-[15vh] w-3 -translate-x-1/2"></div>
	</div>
	<div class="absolute bottom-[15%] left-0 flex md:bottom-[13%]">
		<div class="bg-primary h-3 w-[50vw] self-end xl:w-[34vw] min-[2160px]:w-[50vw]"></div>
		<div class="bg-primary h-[15vh] w-3 -translate-x-1/2"></div>
	</div>
	<h1
		class="relative z-20 text-center text-[1.375rem] font-thin uppercase tracking-[0.15em] text-white sm:text-xl lg:text-[4rem]"
	>
		{nbspify("Máme všechno,")}
		<br />
		<span class="font-bold">
			{nbspify("co potřebujete")}
			<br />
			{nbspify("pro svůj sklad")}
		</span>
	</h1>
	<div class="relative z-20 flex flex-row">
		<Link
			asButton
			href={`/${servicesAnchorPathMap[TENANT_LOCALE]}`}
			variant="primary"
			class="w-[7.5rem] sm:w-[13.75rem] md:text-xs"
		>
			{nbspify("Naše služby")}
		</Link>
		<Link
			asButton
			variant="secondary"
			class="w-[7.5rem] sm:w-[13.75rem] md:text-xs"
			href="/{referencesPathMap[TENANT_LOCALE]}"
		>
			Reference
		</Link>
	</div>
</div>
<a
	href={`/${servicesAnchorPathMap[TENANT_LOCALE]}`}
	class="border-gray text-secondary hover:border-tertiary hover:text-tertiary group mx-auto flex h-[4.5rem] w-[4.5rem] items-center justify-center border"
	aria-label="Služby"
>
	<Icon icon={arrowDown} class="h-4 w-4 transition-transform duration-200 ease-out group-hover:scale-[130%]" />
</a>
